@import "variables", "media-queries", "mixins", "typography";
.section {
    @include section-indent;
    overflow: hidden;
    position: relative;

    .heading {
        max-width: 800px;
        margin-bottom: 40px;

        h2 {
            max-width: 100%;
        }
    }
}

.bg {
    top: 0;
    width: 2540px;
    height: 1130px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(10px);
}

.container {
    position: relative;
}

.calculator {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}
